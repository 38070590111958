<template>
  <div>
    <!-- <div id="contentContainer" class="trans3d" v-if="!!informations?.length">
      <section id="carouselContainer" class="trans3d">
        <figure
          class="carouselItem trans3d"
          v-for="item in informations"
          :key="item.id"
        >
          <div v-if="!item.title" class="carouselItemInner trans3d">
            <b-img-lazy fluid :src="item.url_image" />
          </div>
          <b-card
            v-else
            :bg-variant="item.bg_color ?? 'Default'"
            text-variant="white"
            :header="item.title ?? 'Title'"
            class="text-center"
          >
            <b-card-text>
              {{ item.body }}
            </b-card-text>
            <b-img-lazy
              v-if="item.url_image"
              class="text-center"
              fluid
              :src="String(item.url_image)"
            />
          </b-card>
        </figure>
      </section>
    </div> -->
    <div class="login-box">
      <form>
        <a href="https://landing.salpalaran.com/" target="_blank">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          Visit Company Profile
        </a>
      </form>
    </div>

    <section class="faq-contact">
      <b-row>
        <b-col cols="2">
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-2"
          />
        </b-col>
        <!-- <b-col>
          <div class="demo-spacing-0 mt-5">
            <b-alert show variant="primary">
              <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50" />
                <feather-icon class="mr-25" icon="StarIcon" />
                <span class="ml-25">
                  Selamat pagi bapak dan ibu<br />
                  Salam sehat semuanya <br />
                  <p class="mt-1"></p>
                  Berikut kami sampaikan,
                  <code>rencana maintenance</code> sistem jalur darat PT.SAL
                  yang akan dilakukan pada tanggal 06-09-2023 pukul 21.00 WITA -
                  07-09-2023 06.00 <br />
                  <p class="mt-1"></p>
                  Selama proses maintenance sistem tidak dapat di akses, Kami
                  harapkan maintenance selesai sebelum jam operasi sehingga
                  tidak ada kendala pada operasional di tanggal 07-09-2023<br />
                  <p class="mt-1"></p>
                  Namun jika ada kendala disistem pencatatan akan kami lakukan
                  via manual terlebih dahulu<br />
                  <p class="mt-1"></p>
                  Mohon untuk diketahui<br />
                  <p class="mt-2"></p>
                  Terima kasih<br />
                </span>
              </div>
            </b-alert>
          </div>
        </b-col> -->
      </b-row>

      <vue-particles
        color="#dedede"
        :particleOpacity="0.7"
        :particlesNumber="80"
        shapeType="circle"
        :particleSize="4"
        linesColor="#dedede"
        :linesWidth="1"
        :lineLinked="true"
        :lineOpacity="0.4"
        :linesDistance="150"
        :moveSpeed="3"
        :hoverEffect="true"
        hoverMode="grab"
        :clickEffect="true"
        clickMode="push"
        style="position: absolute; top: 40%; width: 100%"
      ></vue-particles>

      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>Have a trouble?</h2>
          <b-card-text class="mb-3">
            You can always contact us. We will answer to you shortly!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="PhoneCallIcon" size="18" />
            </b-avatar>
            <h4>
              ( <a href="https://wa.me/6282277965188">082277965188</a> ) ⚡️ (
              <a href="https://wa.me/6282352127021">082352127021</a> )
            </h4>
            <span class="text-body">We are always happy to help!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-avatar size="42" rounded variant="light-primary" class="mb-2">
              <feather-icon icon="MailIcon" size="18" />
            </b-avatar>
            <h4>ptsaranaabadilestari@gmail.com</h4>
            <span class="text-body">Best way to get answer faster!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <!-- <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img fluid :src="require('@/assets/images/slider/cansal2.png')" />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img fluid :src="require('@/assets/images/slider/cansal3.png')" />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img fluid :src="require('@/assets/images/slider/cansal4.png')" />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img fluid :src="require('@/assets/images/slider/cansal5.png')" />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img fluid :src="require('@/assets/images/slider/cansal6.png')" />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img fluid :src="require('@/assets/images/slider/cansal7.png')" />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img fluid :src="require('@/assets/images/slider/cansal8.png')" />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img fluid :src="require('@/assets/images/slider/cansal9.png')" />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img
          fluid
          :src="require('@/assets/images/slider/cansal10.png')"
        />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img
          fluid
          :src="require('@/assets/images/slider/cansal11.png')"
        />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img
          fluid
          :src="require('@/assets/images/slider/cansal12.png')"
        />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img
          fluid
          :src="require('@/assets/images/slider/cansal13.png')"
        />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img
          fluid
          :src="require('@/assets/images/slider/cansal14.png')"
        />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img
          fluid
          :src="require('@/assets/images/slider/cansal15.png')"
        />
      </div>
    </figure>
    <figure class="carouselItem trans3d">
      <div class="carouselItemInner trans3d">
        <b-img
          fluid
          :src="require('@/assets/images/slider/cansal16.png')"
        />
      </div>
    </figure> -->
  </div>
</template>

<script>
// import { TweenMax, Power2, gsap } from 'gsap'
// gsap.config({ nullTargetWarn: false })
import $ from 'jquery'
import useJwt from '@/auth/jwt/useJwt'

import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BAlert,
  BRow,
  BCarousel,
  BCarouselSlide,
  BCol,
  BAvatar
} from 'bootstrap-vue'
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    BForm,
    BCard,
    BAlert,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCarousel,
    BCarouselSlide,
    BCardText,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    FaqQuestionAnswer
  },
  data() {
    return {
      informations: null,
      slide: 0,
      sliding: null,
      faqSearchQuery: '',
      isFirst: true,
      tempFaqData: [],
      faqData: [
        {
          icon: 'SettingsIcon',
          categoryName: 'HAK AKSES',
          title: 'HAK AKSES (HA)',
          subtitle: 'level hak akses | otentikasi',
          qandA: [
            {
              question: 'Hak Akses',
              ans: 'Hak akses dibagi menjadi 4, yaitu Super Admin ( management user account ), Host ( management all ), Client ( management form truk masuk dan keluar ) dan Security ( Scanning atau Validation QRCode ).'
            },
            {
              question: 'Super Admin',
              ans: 'Melakukan Tambah, Ubah, dan Hapus terhadap User Account. Setiap account dapat memiliki jenis Auto Approve dan Pending Approve'
            },
            {
              question: 'HOST',
              ans: 'Akun yang memantau semua kejadian ( PT Sarana Abadi Lestari ).'
            },
            {
              question: 'Client',
              ans: 'Akun yang membuat atau menerbitkan Form Masuk dan Keluar'
            },
            {
              question: 'Security',
              ans: 'Akun yang dapat Authentication pada Aplikasi Mobile ( Scanning QRCode ) dan Website ( Monitoring Form Masuk dan Keluar )'
            }
          ]
        },
        {
          icon: 'SettingsIcon',
          categoryName: 'SUB HAK AKSES',
          title: 'SUB HAK AKSES (SHA)',
          subtitle: 'multiple level sub hak akses | otentikasi',
          qandA: [
            // {
            //   question: 'Group',
            //   ans: 'Setiap akun dikelompokkan atau memiliki group, kecuali Security, Super Admin dan Host.',
            // },
            {
              question: 'Sub Hak Akses',
              ans: 'Sub Hak akses dapat dimiliki banyak oleh setiap account, Secara default atau umumnya 📌 Hak Akses Super Admin ( Hanya Manage User ) dan 📌  Hak Akses Security ( Hanya Manage QRCode ). Adapun 📌  Hak Akses Client Sebagai Contoh real, ❶ TEM ( manage agen all,  manage form1 ), ❷.❶ PATRA ( manage spesial ), ❷.❷ PATRA ( manage agen all,  manage form1 ), ❸ PT PIL ( manage agen all, manage form2 ), ❹ SLB ( manage agen all, manage form3 )'
            },
            // {
            //   question: 'Tanda (📌) VS Tanpa Tanda (📌)',
            //   ans: 'Tanda 📌, menunjukkan yang dipakai real sedangkan sebaliknya adalah opsional tergantung management oleh Super Admin.',
            // },
            {
              question: '📌 Manage All',
              ans: 'Default Hanya untuk account dengan Hak Akses HOST. Hanya untuk PT Sarana Abadi Lestari.'
            },
            {
              question: '📌 Manage User',
              ans: 'Default Hanya untuk account dengan Hak Akses Super Admin'
            },
            {
              question: '📌 Manage QRCode',
              ans: 'Default Hanya untuk account dengan Hak Akses Security'
            },
            {
              question: '📌 Manage Agen All',
              ans: 'Default Hanya untuk account dengan Hak Akses Client, → Input data kendaraan, orang/sopir, perusahaan ( agen dan transportir )'
            },
            // {
            //   question: 'Manage Agen',
            //   ans: 'Default Hanya untuk account dengan Hak Akses Client, → Input data kendaraan, perusahaan ( agen dan transportir )',
            // },
            // {
            //   question: 'Manage Transportir',
            //   ans: 'Default Hanya untuk account dengan Hak Akses Client, → Input data orang/sopir dan perusahaan ( transportir )',
            // },
            {
              question: '📌 Manage Special',
              ans: 'Default Hanya untuk account dengan Hak Akses Client dan Group ( Pending Approve ), → Hanya untuk approve form masuk dan keluar, contoh real ❶ PATRA'
            },
            {
              question: '📌 Manage Form1',
              ans: 'Default Hanya untuk account dengan Hak Akses Client, → Membuat, Export Excel dan Monitoring Form Masuk dan Keluar Type 1 (T1), contoh real ❶ TEM dan ❷ PATRA'
            },
            {
              question: '📌 Manage Form2',
              ans: 'Default Hanya untuk account dengan Hak Akses Client, → Membuat, Export Excel dan Monitoring Form Masuk dan Keluar Type 2 (T2), contoh real ❶ PT PIL'
            },
            {
              question: '📌 Manage Form3',
              ans: 'Default Hanya untuk account dengan Hak Akses Client, → Membuat, Export Excel dan Monitoring Form Masuk dan Keluar Type 3 (T3), contoh real ❶ SLB'
            }
            // {
            //   question: 'Sub Hak Akses',
            //   ans: 'Sub Hak Akses dibagi menjadi beberapa ketentuan, yaitu Manage Spesial ( Bertugas untuk approve Form Masuk )',
            // },
          ]
        },
        {
          icon: 'UsersIcon',
          categoryName: 'Type Account',
          title: 'TYPE ACCOUNT',
          subtitle: 'Kondisi ketika membuat form',
          qandA: [
            {
              question: 'Auto Approve',
              ans: 'Otomatis form masuk dan keluar yang dibuat statusnya menjadi Approved'
            },
            {
              question: 'Pending Approve',
              ans: 'Ketika membuat form masuk dan keluar statusnya Pending Approve, membutuhkan (SHA) Sub Hak Akses ( manage special ) untuk melakukan Approve'
            }
          ]
        },
        {
          icon: 'UsersIcon',
          categoryName: 'Group Account',
          title: 'GROUP ACCOUNT',
          subtitle: 'Untuk Pengelompokan data yang tampil',
          qandA: [
            {
              question: 'Jika diisi',
              ans: 'Otomatis data akan masuk berdasarkan group nya. Contoh real ❶ Group: TEM ( data hanya tampil pada group TEM)'
            },
            {
              question: 'Jika tidak diisi (- atau kosong)',
              ans: 'Otomatis data akan masuk dan dapat tampil disemua group'
            }
          ]
        }
        // {
        //   icon: 'TruckIcon',
        //   categoryName: 'MENGISI_FORM_TRUK_MASUK_DAN_KELUAR',
        //   title: 'MENGISI FORM TRUK',
        //   subtitle: 'Mengisi Form Truk Masuk dan Keluar',
        //   qandA: [
        //     {
        //       question: 'question',
        //       ans: 'how',
        //     },
        //     {
        //       question: 'question',
        //       ans: 'how',
        //     },
        //   ],
        // },
      ]
    }
  },
  mounted() {
    // let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute(
    //   'src',
    //   'https://cdnjs.cloudflare.com/ajax/libs/gsap/1.19.1/TweenMax.min.js'
    // )
    // recaptchaScript.setAttribute(
    //   'src',
    //   'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js'
    // )
    // document.head.appendChild(recaptchaScript)
  },
  watch: {
    faqSearchQuery: {
      immediate: true,
      handler() {
        // this.fetchData()
      }
    }
  },
  created() {
    // this.fetchData()
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  methods: {
    // animation() {
    //   this.$nextTick(function () {
    //     // set and cache variables
    //     var w, container, carousel, item, radius, itemLength, rY, ticker, fps
    //     var mouseX = 0
    //     var mouseY = 0
    //     var mouseZ = 0
    //     var addX = 0

    //     // fps counter created by: https://gist.github.com/sharkbrainguy/1156092,
    //     // no need to create my own :)
    //     var fps_counter = {
    //       tick: function () {
    //         // this has to clone the array every tick so that
    //         // separate instances won't share state
    //         this.times = this.times.concat(+new Date())
    //         var seconds,
    //           times = this.times

    //         if (times.length > this.span + 1) {
    //           times.shift() // ditch the oldest time
    //           seconds = (times[times.length - 1] - times[0]) / 1000
    //           return Math.round(this.span / seconds)
    //         } else return null
    //       },

    //       times: [],
    //       span: 20
    //     }
    //     var counter = Object.create(fps_counter)

    //     $(document).ready(init)

    //     function init() {
    //       w = $(window)
    //       container = $('#contentContainer')
    //       carousel = $('#carouselContainer')
    //       item = $('.carouselItem')
    //       itemLength = $('.carouselItem').length
    //       fps = $('#fps')
    //       rY = 360 / itemLength
    //       radius = Math.round(250 / Math.tan(Math.PI / itemLength))

    //       // set container 3d props
    //       TweenMax.set(container, { perspective: 600 })
    //       TweenMax.set(carousel, { z: -radius })

    //       // create carousel item props

    //       for (var i = 0; i < itemLength; i++) {
    //         var $item = item.eq(i)
    //         var $block = $item.find('.carouselItemInner')

    //         //thanks @chrisgannon!
    //         TweenMax.set($item, {
    //           rotationY: rY * i,
    //           z: radius,
    //           transformOrigin: '50% 50% ' + -radius + 'px'
    //         })

    //         animateIn($item, $block)
    //       }

    //       // set mouse x and y props and looper ticker
    //       window.addEventListener('mousemove', onMouseMove, false)
    //       ticker = setInterval(looper, 1000 / 60)
    //     }

    //     function animateIn($item, $block) {
    //       var $nrX = 360 * getRandomInt(2)
    //       var $nrY = 360 * getRandomInt(2)

    //       var $nx = -2000 + getRandomInt(4000)
    //       var $ny = -2000 + getRandomInt(4000)
    //       var $nz = -4000 + getRandomInt(4000)

    //       var $s = 1.5 + getRandomInt(10) * 0.1
    //       var $d = 1 - getRandomInt(8) * 0.1

    //       TweenMax.set($item, { autoAlpha: 1, delay: $d })
    //       TweenMax.set($block, {
    //         z: $nz,
    //         rotationY: $nrY,
    //         rotationX: $nrX,
    //         x: $nx,
    //         y: $ny,
    //         autoAlpha: 0
    //       })
    //       TweenMax.to($block, $s - 0.5, {
    //         delay: $d,
    //         rotationY: 0,
    //         rotationX: 0,
    //         z: 0,
    //         scale: 1.1,
    //         ease: Power2.easeInOut
    //       })
    //       TweenMax.to($block, $s - 0.8, {
    //         delay: $d,
    //         x: 0,
    //         y: 0,
    //         autoAlpha: 1,
    //         ease: Power2.easeInOut
    //       })
    //     }

    //     function onMouseMove(event) {
    //       mouseX = -(-(window.innerWidth * 0.5) + event.pageX) * 0.0025
    //       mouseY = -(-(window.innerHeight * 0.5) + event.pageY) * 0.01
    //       mouseZ =
    //         -radius -
    //         (Math.abs(-(window.innerHeight * 0.5) + event.pageY) - 200)
    //     }

    //     // loops and sets the carousel 3d properties
    //     function looper() {
    //       addX += mouseX
    //       TweenMax.to(carousel, 0.5, {
    //         rotationY: addX,
    //         rotationX: mouseY,
    //         ease: Power2.easeOut
    //       })
    //       TweenMax.set(carousel, { z: mouseZ })
    //       fps.text('Framerate: ' + counter.tick() + '/60 FPS')
    //     }

    //     function getRandomInt($n) {
    //       return Math.floor(Math.random() * $n + 1)
    //     }
    //   })
    // },
    // async fetchData() {
    //   await useJwt.http.get(`ai_information`).then((response) => {
    //     this.informations = response.data.ai_informations
    //     console.log('this.informations:::', this.informations)
    //     this.animation()
    //   })
    // },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
<style>
.login-box {
  padding: 10px;
  box-sizing: border-box;

  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}
.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box input:focus ~ label,
.login-box .user-box input:valid ~ label {
  top: -20px;
  left: 0;
  color: #032bf4;
  font-size: 12px;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #032bf4;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 4px;
}

.login-box a:hover {
  background: #032bf4;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #032bf4, 0 0 25px #032bf4, 0 0 50px #032bf4,
    0 0 100px #032bf4;
}

.login-box a span {
  position: absolute;
  display: block;
}

.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #032bf4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #032bf4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #032bf4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #032bf4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
</style>

<!-- <style>
header {
  margin-top: 30px;
  position: absolute;
  z-index: 5;
  width: 420px;
  padding-top: 10px;
  padding-bottom: 10px;
}

h1 {
  font-size: 36px;
  letter-spacing: -2.5px;
  margin-left: 30px;
}

h3 {
  font-size: 16px;
  letter-spacing: -1.5px;
  margin-top: 5px;
  margin-left: 35px;
}

#fps {
  margin-top: 5px;
  margin-left: 35px;
}

a {
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
}

a:hover {
  color: rgba(255, 255, 255, 1);
}
.trans3d {
  -webkit-transform-style: preserve-3d;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform-style: preserve-3d;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform-style: preserve-3d;
  -ms-transform: translate3d(0, 0, 0);
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
}

#contentContainer {
  position: absolute;
  margin-left: -500px;
  margin-top: -600px;
  left: 50%;
  top: 50%;
  width: 1000px;
  height: 1000px;
}

#carouselContainer {
  position: absolute;
  margin-left: -500px;
  margin-top: -500px;
  left: 50%;
  top: 50%;
  width: 1000px;
  height: 1000px;
}

.carouselItem {
  width: 320px;
  height: 130px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -160px;
  margin-top: -90px;
  visibility: hidden;
}

.carouselItemInner {
  width: 320px;
  height: 270px;
  position: absolute;
  font-size: 72px;
  left: 50%;
  top: 50%;
  margin-left: -160px;
  margin-top: -90px;
  text-align: center;
  padding-top: 50px;
}
</style> -->
