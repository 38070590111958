var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('section', {
    staticClass: "faq-contact"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-img', {
    staticClass: "d-none d-md-block mt-2",
    attrs: {
      "fluid": "",
      "src": require('@/assets/images/illustration/faq-illustrations.svg')
    }
  })], 1)], 1), _c('vue-particles', {
    staticStyle: {
      "position": "absolute",
      "top": "40%",
      "width": "100%"
    },
    attrs: {
      "color": "#dedede",
      "particleOpacity": 0.7,
      "particlesNumber": 80,
      "shapeType": "circle",
      "particleSize": 4,
      "linesColor": "#dedede",
      "linesWidth": 1,
      "lineLinked": true,
      "lineOpacity": 0.4,
      "linesDistance": 150,
      "moveSpeed": 3,
      "hoverEffect": true,
      "hoverMode": "grab",
      "clickEffect": true,
      "clickMode": "push"
    }
  }), _c('b-row', {
    staticClass: "mt-5 pt-75 text-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', [_vm._v("Have a trouble?")]), _c('b-card-text', {
    staticClass: "mb-3"
  }, [_vm._v(" You can always contact us. We will answer to you shortly! ")])], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', {
    staticClass: "shadow-none py-1 faq-contact-card"
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "size": "42",
      "rounded": "",
      "variant": "light-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "PhoneCallIcon",
      "size": "18"
    }
  })], 1), _c('h4', [_vm._v(" ( "), _c('a', {
    attrs: {
      "href": "https://wa.me/6282277965188"
    }
  }, [_vm._v("082277965188")]), _vm._v(" ) ⚡️ ( "), _c('a', {
    attrs: {
      "href": "https://wa.me/6282352127021"
    }
  }, [_vm._v("082352127021")]), _vm._v(" ) ")]), _c('span', {
    staticClass: "text-body"
  }, [_vm._v("We are always happy to help!")])], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('b-card', {
    staticClass: "shadow-none py-1 faq-contact-card"
  }, [_c('b-avatar', {
    staticClass: "mb-2",
    attrs: {
      "size": "42",
      "rounded": "",
      "variant": "light-primary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon",
      "size": "18"
    }
  })], 1), _c('h4', [_vm._v("ptsaranaabadilestari@gmail.com")]), _c('span', {
    staticClass: "text-body"
  }, [_vm._v("Best way to get answer faster!")])], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "login-box"
  }, [_c('form', [_c('a', {
    attrs: {
      "href": "https://landing.salpalaran.com/",
      "target": "_blank"
    }
  }, [_c('span'), _c('span'), _c('span'), _c('span'), _vm._v(" Visit Company Profile ")])])]);
}]

export { render, staticRenderFns }